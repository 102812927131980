import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Resources } from './api.service';

@Injectable()
export class ApiEtlService {


    constructor(
        private apiService: ApiService
    ) {}

    public getCurrentDay(): Observable<any> {
        return this.apiService.get(Resources.ETL_TODAY);
    }

}
