import { Injectable } from '@angular/core';
import { SessionService } from '@minubo-suite/shared/services/session.service';
import { ApiSessionService } from '@minubo-portal/modules/api/services/api-session.service';
import { filter, take } from 'rxjs/operators';

export type LocalSettingsStorePrefix = 'dataProvider' | 'report' | 'dashboard';

@Injectable()
export class LocalSettingsStore<T> {

    private portalPartnerId: number;

    public constructor(sessionService: ApiSessionService) {
        sessionService.sessionState$.pipe(
            filter(state => state && state.authenticated && !!state.portalInfo),
            take(1)
        ).subscribe(session => {
            this.portalPartnerId = session.portalInfo.portalPartnerId;
        });
    }

    private buildKey(prefix: LocalSettingsStorePrefix, settingsId: string|number): string {
        return `v1.${prefix}_${this.portalPartnerId}_${settingsId}`;
    }

    public store(prefix: LocalSettingsStorePrefix, settingsId: string|number, settings: T) {
        const localSettings = this.loadSettingsFromStorage();
        const entityEmbedmentCodes = Object.keys(localSettings);
        const updateOrder = Object.keys(localSettings).map(key => localSettings[key].updateOrder).reduce((a, b) => Math.max(a, b), 0) + 1;

        const settingsKey = this.buildKey(prefix, settingsId);
        localSettings[settingsKey] =  {
            settings: settings,
            updateOrder: (localSettings[settingsKey] && localSettings[settingsKey].updateOrder === updateOrder - 1 ? updateOrder - 1  : updateOrder)
        };

        if (entityEmbedmentCodes.length > 100) {
            const leastUpdateOrder = entityEmbedmentCodes.reduce((a, b) => {
                const aTemp = localSettings[a];
                const bTemp = localSettings[b];
                return aTemp.updateOrder < bTemp.updateOrder ? a : b;
            });

            delete localSettings[leastUpdateOrder];
        }

        this.storeSettingsToStorage(localSettings);
    }

    public load(prefix: LocalSettingsStorePrefix, settingsId: string|number): T {
        const localSettings = this.loadSettingsFromStorage();
        const settingsKey = this.buildKey(prefix, settingsId);
        return localSettings[settingsKey] ? localSettings[settingsKey].settings : null;
    }

    private loadSettingsFromStorage(): LocalSettings<T> {
        const json = window.localStorage.getItem('mnb-portal-settings');
        const localSettings = json ? JSON.parse(json) : null;
        return localSettings ? localSettings : new LocalSettings<T>();
    }

    private storeSettingsToStorage(value: LocalSettings<T>): void {
        window.localStorage.setItem('mnb-portal-settings', JSON.stringify(value));
    }

}

class LocalSettings<T> {
    [entityId: string]: { settings: T, updateOrder: number };
}
