import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { PortalDashboard } from '@minubo-portal/modules/api/models/api-dashboard.model';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';
import { ApiDashboardService } from '@minubo-portal/modules/api/services/api-dashboard.service';
import { ApiDataService } from '@minubo-portal/modules/api/services/api-data.service';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { DashboardGrid, DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { Dashboard, DashboardSettings, DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, skip } from 'rxjs/operators';
import { DashboardsFilterModel, DashboardsFiltersModel, mapFiltersModel } from '../../model/dashboards-filters.model';
import { DashboardsDashboardService } from '../../services/dashboards-dashboard.service';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';


@Component({
    templateUrl: './dashboards-dashboard-page.component.html'
})
export class DashboardsDashboardPageComponent implements OnInit {

    public model$ = new BehaviorSubject<DashboardsDashboardPageModel>(null);

    private context: DashboardsDashboardPageContext;

    public rawFilters$: Observable<QueryFilter[]> = this.model$.pipe(
        map(model => model.filters.filters.map(f => f.filter))
    );
    public rawTimeFilter$: Observable<MnbQuickTimeFilterValue> = this.model$.pipe(
        map(model => ({timeFilter: model.filters.timeFilter, comparisonFilter: model.filters.comparisonFilter}))
    );

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiDataService: ApiDataService,
        private dashboardService: DashboardsDashboardService
    ) {

    }

    ngOnInit() {
        const context$ = this.route.data.pipe(
            map((data: { context: DashboardsDashboardPageContext }) => data.context)
        );

        // store context
        context$.subscribe(context => this.context = context);

        const dashboard$ = context$.pipe(
            mergeMap(context => context.dashboard$),
            filter(portalDashboard => !!portalDashboard)
        );

        const portalEntity$ = context$.pipe(
            mergeMap(context => this.dashboardService.getEntity(context.id))
        );

        // set up model with context and loaded dashboard
        combineLatest([dashboard$, portalEntity$]).subscribe(data => {
            const [ dashboard, portalEntity ] = data;
            this.model$.next(this.initModel(dashboard, portalEntity));
        });

        // skip initial null and first model
        this.model$.pipe(skip(2)).subscribe((model) => {

            // map to new view settings
            const viewSettings = mapFiltersModel(model.filters);

            // update widgets
            this.updateWidgets(model, viewSettings);

            // update context to be able to load them when coming to (and back from) detail views
            this.context.updateSettings(viewSettings);

        });
    }

    private initModel(portalDashboard: PortalDashboard, portalEntity: PortalEntity) {
        const dashboard: Dashboard = {
            ...portalDashboard
        };

        const filters = portalEntity.settings.filters || [];
        const filterModels = filters.map(settingsFilter => {
            settingsFilter.values = settingsFilter.values || [];
            const filterModel: DashboardsFilterModel = {
                filter: (this.context.filters || []).find(f => f.attributeCode === settingsFilter.attributeCode) || settingsFilter,
                originalFilter: deepCopy(settingsFilter)
            };
            return filterModel;
        });

        const filtersModel: DashboardsFiltersModel = {
            timeFilter: this.context.timeFilter || dashboard.settings.timeFilter,
            comparisonFilter: this.context.timeFilter ? this.context.comparisonFilter : dashboard.settings.comparisonFilter,
            filters: filterModels,
        };

        let lastUsedRow = 0;

        // get number of rows used, cut off all rows that are not needed
        dashboard.widgets.forEach((widget: DashboardWidget) => {
            if (widget.visualizationSettings.position.y + widget.visualizationSettings.position.height > lastUsedRow) {
                lastUsedRow = widget.visualizationSettings.position.y + widget.visualizationSettings.position.height;
            }
        });

        const viewDashboard = new Dashboard();
        viewDashboard.id = dashboard.id;
        viewDashboard.settings = mapFiltersModel(filtersModel);
        viewDashboard.widgets = dashboard.widgets;

        const model: DashboardsDashboardPageModel = {
            dashboard,
            grid: this.dashboardService.buildGrid(viewDashboard, null, lastUsedRow),
            filters: filtersModel
        };

        return model;
    }

    public loadFilterValues = (attributeCode: string, filterSearch?: string) => {
        return this.apiDataService.loadAttributeData(attributeCode, filterSearch).toPromise();
    }

    public onFilterChange(value: QueryFilter, filterModel: DashboardsFilterModel) {
        filterModel.filter = value;
        this.model$.next({
            ...this.model$.value
        });
    }

    public onTimeFilterChange(value: MnbQuickTimeFilterValue): void {
        this.model$.next({
            ...this.model$.value,
            filters: {
                ...this.model$.value.filters,
                timeFilter: value.timeFilter,
                comparisonFilter: value.comparisonFilter
            }
        });
    }

    public showDetailView(gridWidget: DashboardGridWidget) {
        void this.router.navigate(['dashboards', gridWidget.dashboard.id, 'widgets', gridWidget.widget.id]);
    }

    private updateWidgets(model: DashboardsDashboardPageModel, viewSettings: DashboardSettings): void {
        const viewDashboard = new Dashboard();
        viewDashboard.id = model.dashboard.id;
        viewDashboard.settings = viewSettings;
        model.grid.cells.filter(cell => cell.widget).forEach(cell => {
            this.dashboardService.loadWidget(cell.widget, cell.widget.widget, viewDashboard);
        });
    }
}

@Injectable()
export class DashboardsDashboardPageContextResolver implements Resolve<DashboardsDashboardPageContext> {

    constructor(
        private apiDashboardService: ApiDashboardService,
        private trackingService: ApiTrackingService,
        private settingsStore: LocalSettingsStore<DashboardSettings>
    ) {
    }

    resolve(route: ActivatedRouteSnapshot) {

        const context = new DashboardsDashboardPageContext(route.params['dashboardId'], this.settingsStore);

        this.apiDashboardService.loadDashboard(route.params['dashboardId']).subscribe(portalDashboard => {
            (<AppHeader> route.data.header).title = portalDashboard.title;
            const parameters = {
                'dashboardId': portalDashboard.id,
                'dashboardTitle': portalDashboard.title,
            };
            this.trackingService.tracking('dashboard', 'view', parameters);

            context.dashboard$.next(portalDashboard);
        });

        return context;
    }
}

export class DashboardsDashboardPageContext {

    public dashboard$ = new BehaviorSubject<PortalDashboard>(null);

    public timeFilter?: QuerySettingsTimeFilter;
    public comparisonFilter?: QuerySettingsComparisonFilter;
    public filters?: QueryFilter[];

    constructor(public readonly id: number, private settingsStore: LocalSettingsStore<DashboardSettings>) {
        // load settings
        const storedSettings = this.settingsStore.load('dashboard', id);

        if (storedSettings) {
            this.timeFilter = storedSettings.timeFilter;
            this.comparisonFilter = storedSettings.comparisonFilter;
            this.filters = storedSettings.filters;
        }
    }

    public updateSettings(settings: DashboardSettings) {

        this.timeFilter = settings.timeFilter;
        this.comparisonFilter = settings.comparisonFilter;
        this.filters = settings.filters;

        // store settings
        this.settingsStore.store('dashboard', this.id, settings);
    }
}

export interface DashboardsDashboardPageModel {
    dashboard: Dashboard;
    grid: DashboardGrid;
    filters: DashboardsFiltersModel;
}
