import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';


@Component({
    templateUrl: './reports-report-page.component.html'
})
export class ReportsReportPageComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute
    ) {
    }

    public context$: Observable<ReportsReportPageContext>;


    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        this.context$ = this.route.data.pipe(
            map((data: {context: ReportsReportPageContext}) => data.context)
        );

    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

@Injectable()
export class ReportsReportPageContextResolver implements Resolve<ReportsReportPageContext> {


    constructor(
        private apiReportService: ApiReportService,
        private trackingService: ApiTrackingService,
        private settingsStore: LocalSettingsStore<ReportSettings>
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<ReportsReportPageContext> {
        const reportId = route.params['reportId'];

        const localSettings = this.settingsStore.load('report', reportId);

        const result = this.apiReportService.loadReport(reportId).pipe(
            map(portalReport => {
                (<AppHeader>route.data.header).title = portalReport.title;
                return {
                    report: portalReport,
                    localSettings: localSettings
                };
            }),
        );
        result.subscribe(report => {
            const parameters = {
                'reportId': report.report.id,
                'reportTypeCode': report.report.typeCode,
                'reportTitle': report.report.title,
            };
            this.trackingService.tracking('report', 'view', parameters);
        });
        return result;
    }
}

export type ReportsReportPageContext = {

    report: PortalReport;
    localSettings: ReportSettings | null;

};
