import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardsDashboardService } from './services/dashboards-dashboard.service';
import { MnbDashboardsModule } from '@shared-lib/modules/dashboards/mnb-dashboards.module';
import { MnbQueriesModule } from '@shared-lib/modules/queries/mnb-queries.module';
import { DashboardsWidgetDetailPageComponent } from './pages/widget-detail/dashboards-widget-detail-page.component';
import { DashboardsDashboardPageComponent, DashboardsDashboardPageContextResolver } from './pages/dashboard/dashboards-dashboard-page.component';
import { MnbFiltersModule } from '@shared-lib/modules/filters/mnb-filters.module';
import { UtilsModule } from '@minubo-portal/utils/utils.module';

@NgModule({
    declarations: [
        DashboardsDashboardPageComponent,
        DashboardsWidgetDetailPageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MnbLibModule,
        UtilsModule,
        RouterModule.forChild([{
            path: ':dashboardId',
            resolve: {
                context: DashboardsDashboardPageContextResolver
            },
            data: {
                header: <AppHeader> {
                    title: ''
                }
            },
            children: [{
                path: '',
                component: DashboardsDashboardPageComponent,
            }, {
                path: 'widgets/:widgetId',
                component: DashboardsWidgetDetailPageComponent,
                data: {
                    header: <AppHeader> {
                        title: ''
                    }
                }
            }]
        }]),
        TranslateModule,
        MnbDashboardsModule,
        MnbFiltersModule,
        MnbQueriesModule
    ],
    exports: [],
    providers: [
        DashboardsDashboardService,

        DashboardsDashboardPageContextResolver
    ]
})
export class DashboardsModule {

}
