import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/minubo-portal/src/environments/environment';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { PortalLanguageSwitchRequest, PortalLanguageSwitchResponse, PortalPartnerSwitchRequest, PortalSession } from '../models/api-login.model';
import { ApiService, Resources } from './api.service';
import { initSentryUserContext } from '@shared-lib/tooling/sentry/sentry.util';

@Injectable()
export class ApiSessionService implements OnDestroy {

    public sessionState$ = new ReplaySubject<PortalSession>(1);
    public initializedState$ = new BehaviorSubject<boolean>(false);

    public hasWipFeatures$: Observable<boolean>;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private translateService: TranslateService
    ) {
        this.loadSession();
        this.translateService.addLangs(['de', 'en']);

        this.hasWipFeatures$ = this.sessionState$.pipe(map(session => session.hasWipFeatures));
    }

    public loadSession(): void {
        // We need something that shows us that the user is logged in.
        // For now, we can call this endpoint, but we may change this in the future.
        this.http.get(`${environment.apiUrl}/v3/session`, {
            withCredentials: true
        }).pipe(
            catchError((_: any) => {
                this.sessionState$.next({ authenticated: false, login: null });
                this.initializedState$.next(true);
                return EMPTY;
            })
        ).subscribe((response: PortalSession) => {
            this.sessionState$.next(response);
            this.initializedState$.next(true);

            if (response.authenticated && response.login && response.tenant && response.portalInfo) {
                initSentryUserContext({
                    id: response.login.id,
                    tenantId: response.tenant.id,
                    portalId: response.portalInfo.portalId,
                    partnerId: response.portalInfo.portalPartnerId
                });
            }

            // Use browser language as default in case of missing login. if de -> de, else en
            const language = response.login
                ? response.login.language
                : this.translateService.getBrowserLang() === 'de'
                    ? 'de'
                    : 'en';
            this.translateService.use(language);
        });
    }

    public refreshSession(): Observable<PortalSession> {
        this.loadSession();
        return this.sessionState$;
    }

    public async changeLanguage(language: string): Promise<void> {
        const session = await this.sessionState$
            .pipe(
                take(1)
            ).toPromise();

        if (!session.authenticated) {
            this.translateService.use(language);
        } else {
            this.apiService.save<PortalLanguageSwitchRequest, PortalLanguageSwitchResponse>(Resources.SESSION_LANGUAGE, {language}).subscribe(() => {
                window.location.reload();
            });
        }
    }

    public async changePartner(partnerId: number) {
        const session = await this.sessionState$
            .pipe(
                take(1)
            ).toPromise();

        if (session.authenticated) {
            this.apiService.save<PortalPartnerSwitchRequest, void>(Resources.SESSION_PARTNER, {partnerId}).subscribe(() => {
                window.location = <any> '';
            });
        }
    }

    ngOnDestroy() {
        this.sessionState$.complete();
        this.initializedState$.complete();
    }

}
