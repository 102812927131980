import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';



@NgModule({
  providers: [
      LocalSettingsStore,
  ],
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class UtilsModule { }
