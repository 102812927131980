import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';
import { initSentry } from '@shared-lib/tooling/sentry/sentry.util';

if (environment.production) {
    initSentry({
        dsn: 'https://d9e53311e6e071745fbe2d67aa03bfc0@o4508216392089602.ingest.de.sentry.io/4508240319283280',
        stage: environment.stage,
        release: environment.release
    });
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
