import { Injectable } from '@angular/core';
import { ApiService, Resources } from './api.service';
import { Observable } from 'rxjs';
import { DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';
import { PortalDataProvider, PortalDataProviderData } from '@minubo-portal/modules/api/models/api-data-provider.model';


@Injectable()
export class ApiDataProviderService {

    constructor(
        private apiService: ApiService
    ) { }

    public loadDataProviders(): Observable<PortalDataProvider[]> {
        return this.apiService.query(Resources.DATA_PROVIDER);
    }

    public loadDataProvider(id: number): Observable<PortalDataProvider> {
        return this.apiService.get(Resources.DATA_PROVIDER, {id});
    }

    public loadDataProviderData(id: number, settings: DataProviderSettings): Observable<PortalDataProviderData> {
        return this.apiService.post(Resources.DATA_PROVIDER_DATA, settings, { id });
    }

    public loadReportAdhocFeedUrl(id: number, settings: DataProviderSettings): Observable<{ url: string }> {
        return this.apiService.post(Resources.DATA_PROVIDER_ADHOC_FEED, settings, { id });
    }

}
