import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportData, ReportRankComparison, ReportSettings, ReportSettingsDrilldownStep } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DrilldownFilterContext, mapToDrilldownContext, MnbReportDrilldownStepModel } from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { TraceClassDecorator } from '@sentry/angular';


@TraceClassDecorator()
@Component({
    selector: 'reports-rank-comparison',
    templateUrl: './reports-rank-comparison.component.html'
})
export class ReportsRankComparisonComponent implements OnInit, OnDestroy {

    public form: FormGroup;

    public viewSettings$ = new BehaviorSubject<ReportSettings>(null);

    public report: PortalReport;
    public data: ReportData;

    public drilldownContextList: DrilldownFilterContext[] = [];

    private viewSettingsFormValue$ = new Subject<ReportsTemplateFormValue>();
    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'comparisonFilter': new FormControl(null),
            'filters': new FormControl([]),
            'selectedBenchmark': new FormControl(null),
            'selectedStore': new FormControl(null),
        });

        this.viewSettingsFormValue$
            .pipe(
                map(settings => toViewSettings(settings)),
                withLatestFrom(this.viewSettings$),
                takeUntil(this.destroy$)
            ).subscribe(([formSettings, latestFrom]) => {
                const settings = {
                    ...latestFrom,
                    ...formSettings
                };
                if (this.drilldownContextList != null && this.drilldownContextList.length > 0) {
                    settings.rankComparison.selectedDrilldownFilters = this.drilldownContextList.map(drilldown => drilldown.filter);
                }
            this.viewSettings$.next(settings);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;

        this.form.get('timeFilter').setValue(settings.rankComparison.timeFilter);
        this.form.get('comparisonFilter').setValue(settings.rankComparison.comparisonFilter);
        this.form.get('filters').setValue(settings.rankComparison.filters || []);
        this.form.get('selectedBenchmark').setValue(settings.rankComparison.selectedBenchmark);
        this.form.get('selectedStore').setValue(settings.rankComparison.selectedStore);
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        this.viewSettingsFormValue$.next(formValue);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
    }

    onBenchmarkSelectionChanged(benchmarkName: string) {
        this.form.get('selectedBenchmark').setValue(benchmarkName);
    }

    onStoreFilterSelectionChanged(storeFilterValue: string) {
        if (this.form.get('selectedStore').value !== storeFilterValue) {
            this.form.get('selectedStore').setValue(storeFilterValue);
        }
    }

    onDrilldown(step: ReportSettingsDrilldownStep) {

        const newFilters: QueryFilter[] = [];

        for (const attr in step.keyValues) {
            if (!step.keyValues.hasOwnProperty(attr)) {
                continue; // Linting expects this guard-condition when using for-in loops
            }
            const newDrilldownFilter: QueryFilter = {
                typeCode: 'equals',
                attributeCode: attr,
                values: [step.keyValues[attr]]
            };
            newFilters.push(newDrilldownFilter);
        }

        const viewSettingsDrilldown = this.viewSettings$.value.rankComparison;
        const currentDrilldownFilters = !!viewSettingsDrilldown.selectedDrilldownFilters
            ? [...this.viewSettings$.value.rankComparison.selectedDrilldownFilters]
            : [];

        // Updating drilldownContext
        this.drilldownContextList = [
            ...this.drilldownContextList,
            ...mapToDrilldownContext(newFilters, step.keyValues, step.nameValues)
        ];

        // Updating viewSettings
        this.viewSettings$.next({
            rankComparison: {
                ...viewSettingsDrilldown,
                selectedDrilldownFilters: [...currentDrilldownFilters, ...newFilters],
            }
        });
    }

    onDrillup(event:  MnbReportDrilldownStepModel) {
        const newFilters = [];
        if (!event) {
            this.drilldownContextList = [];
        } else if (!!event) {
            const currentSettings = this.viewSettings$.value.rankComparison;
            const removedFilterIdx = currentSettings.selectedDrilldownFilters
                .findIndex(selected => selected.attributeCode === event.attributeCode);
            // 'Clicked on' should be included, thus idx+1
            newFilters.push(...currentSettings.selectedDrilldownFilters.slice(0, removedFilterIdx + 1));
            this.drilldownContextList = this.drilldownContextList.slice(0, removedFilterIdx + 1);
        }

        this.viewSettings$.next({
            rankComparison: {
                ...this.viewSettings$.value.rankComparison,
                selectedDrilldownFilters: newFilters
            }
        });
    }
}

function toViewSettings(formValue: Partial<ReportRankComparison>): ReportSettings {
    const viewSettings = new ReportSettings();
    viewSettings.rankComparison = {
        timeFilter: formValue.timeFilter,
        comparisonFilter: formValue.comparisonFilter,
        filters: formValue.filters,
        selectedBenchmark: formValue.selectedBenchmark,
        selectedStore: formValue.selectedStore,
    };
    return viewSettings;
}
