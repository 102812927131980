import { Report, ReportData, ReportSettings, } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { ReportTableRow, ReportSettingsTable, ReportSettingsTableMeasure, ReportSettingsTableMeasureSettings, ReportTableData, TableSort } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { isNullOrUndefined } from 'util';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { ModelAttribute, ModelMeasure, MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { ValueBarCellSettings } from '@shared-lib/modules/core/components/value-bar-cell/value-bar-cell.component';
import { QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import deepEqual from 'deep-equal';

@Component({
    selector: 'mnb-reports-table-display',
    exportAs: 'displayComponent',
    templateUrl: './mnb-reports-table-display.component.html',
    styleUrls: ['./mnb-reports-table-display.component.less']
})
export class MnbReportsTableDisplayComponent implements OnInit, OnChanges, OnDestroy {

    private destroy$ = new Subject<void>();

    @Input() set report(report: Report) {
        this.report$.next(report);
    }
    @Input() set viewSettings(viewSettings: ReportSettings) {
        this.viewSettings$.next(viewSettings);
    }
    @Input() set data(data: ReportData) {
        if (!!data && !!data.table) {
            this.data$.next(data.table);
        }
    }
    @Input() set availableAttributes(availableAttributes: { code: string }[]) {
        this.availableAttributes$.next(availableAttributes);
    }

    @Input() isPreview: boolean;
    @Input() showHelpButton = false;
    @Input() showSortButtons = false;

    @Output() viewSettingsEmitter: EventEmitter<ReportSettings> = new EventEmitter();
    @Output() drilldownEventEmitter = new EventEmitter();
    @Output() openHelpEmitter = new EventEmitter<ModelMeasure>();

    private report$ = new BehaviorSubject<Report>(null);
    protected viewSettings$ = new BehaviorSubject<ReportSettings>(null);
    private data$ = new BehaviorSubject<ReportTableData>({
        rows: [],
        values: {},
        compareValues: {},
        hasMore: false,
        estimatedTotalRows: 0,
    });
    private availableAttributes$ = new BehaviorSubject<{ code: string }[]>([]);

    private attributes$ = new BehaviorSubject<ModelAttribute[]>([]);
    private measures$ = new BehaviorSubject<ModelMeasure[]>([]);
    private measuresSettings$ = new BehaviorSubject<ReportSettingsTableMeasure[]>([]);

    private availableDownloadAttributes$ = new BehaviorSubject<ModelAttribute[]>([]);
    private availableDownloadMeasures$ = new BehaviorSubject<ModelMeasure[]>([]);

    private timeFilter$: Observable<QuerySettingsTimeFilter>;
    private comparisonFilter$: Observable<QuerySettingsComparisonFilter>;

    private timeSpan$: BehaviorSubject<DateSpan> = new BehaviorSubject(null);
    private comparisonTimeSpan$: BehaviorSubject<DateSpan> = new BehaviorSubject(null);

    private tableModel$ = new BehaviorSubject<TableModel>(null);

    public comparisonTimeSpan: DateSpan;
    public previewAttributeRows: Array<Array<TableAttribute>>;

    constructor(
        private timeFilterService: TimeFilterService,
        private modelService: MnbModelService,
    ) { }

    ngOnInit(): void {

        combineLatest([this.report$, this.viewSettings$]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            ([report, viewSettings]) => {
                let attributes = [];

                if (viewSettings && viewSettings.table && viewSettings.table.attributes) {
                    attributes = deepCopy(viewSettings.table.attributes);
                } else if (report && report.settings) {
                    attributes = deepCopy(report.settings.table.attributes);
                } else {
                    return;
                }

                const attributePromises: Promise<ModelAttribute>[] = attributes.map(
                    attribute => this.modelService.getAttribute(attribute.code)
                );
                Promise.all(attributePromises).then((result) => this.attributes$.next(result));
            }
        );

        this.report$.pipe(takeUntil(this.destroy$)).subscribe((report) => {
            if (!report) {
                return;
            }

            const measurePromises: Promise<ModelMeasure>[] = report.settings.table.measures.map(
                (measure) => {
                    return this.modelService.getMeasure(measure.code);
                }
            );
            Promise.all(measurePromises).then((result) => this.measures$.next(result));

            if (report.settings.table.measures) {
                this.measuresSettings$.next(report.settings.table.measures);
            }

            if (report && report.settings && report.settings.table && report.settings.table.attributes) {
                let attributeCodes = report.settings.table.attributes.map(attr => attr.code);
                if (report.settings.table.availableDownloadAttributes) {
                    attributeCodes = attributeCodes.concat(report.settings.table.availableDownloadAttributes.map(attr => attr.code));
                    attributeCodes = attributeCodes.filter((value, index, array) => array.indexOf(value) === index);
                }
                const availableDownloadAttributePromises: Promise<ModelAttribute>[] = attributeCodes.map(
                    attributeCode => this.modelService.getAttribute(attributeCode)
                );
                Promise.all(availableDownloadAttributePromises).then(result => this.availableDownloadAttributes$.next(result));
            }

            if (report && report.settings && report.settings.table && report.settings.table.measures) {
                let measureCodes = report.settings.table.measures.map(measure => measure.code);
                if (report.settings.table.availableDownloadMeasures) {
                    measureCodes = measureCodes.concat(report.settings.table.availableDownloadMeasures.map(measure => measure.code));
                    measureCodes = measureCodes.filter((value, index, array) => array.indexOf(value) === index);
                }
                const availableDownloadMeasurePromises: Promise<ModelMeasure>[] = measureCodes.map(
                    measureCode => this.modelService.getMeasure(measureCode)
                );
                Promise.all(availableDownloadMeasurePromises).then(result => this.availableDownloadMeasures$.next(result));
            }

        });

        // default sort
        this.viewSettings$.pipe(
            tap(
                viewSettings => {
                    let sort = null;
                    const report = this.report$.getValue();
                    if (report && report.settings && report.settings.table && report.settings.table.sort) {
                        sort = report.settings.table.sort;
                    }

                    if (isNullOrUndefined(viewSettings.table.sort) && sort) {
                        const newViewSettings = new ReportSettings();
                        newViewSettings.table = { sort };
                        this.viewSettingsEmitter.emit(newViewSettings);
                    }
                }
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        // timespan
        this.viewSettings$.pipe(
            map(viewSettings => viewSettings.table.timeFilter),
            distinctUntilChanged(deepEqual),
            map(timeFilter => this.timeFilterService.getTimePeriod(timeFilter)),
            takeUntil(this.destroy$)
        ).subscribe(timeSpan => this.timeSpan$.next(timeSpan));

        this.timeFilter$ = this.viewSettings$.pipe(
            map(viewSettings => viewSettings.table.timeFilter),
            distinctUntilChanged(deepEqual)
        );

        this.comparisonFilter$ = this.viewSettings$.pipe(
            map(viewSettings => viewSettings.table.comparisonFilter),
            distinctUntilChanged(deepEqual)
        );

        // comparisonTimespan
        combineLatest([this.timeFilter$, this.comparisonFilter$]).pipe(
            filter(([timeFilter, comparisonFilter]) => !!timeFilter && !!comparisonFilter),
            map(([timeFilter, comparisonFilter]) => this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter> comparisonFilter)),
            takeUntil(this.destroy$)
        ).subscribe(comparisonTimeSpan => this.comparisonTimeSpan$.next(comparisonTimeSpan));

        combineLatest([this.data$, this.viewSettings$, this.availableAttributes$, this.attributes$, this.measures$, this.measuresSettings$]).pipe(
            map(([data, viewSettings, availableAttributes, attributes, measures, measuresSettings]) =>  {

                const report = this.report$.getValue();
                if (isNullOrUndefined(report) || isNullOrUndefined(viewSettings)) {
                    return;
                }

                const comparisonFilter = viewSettings.table.comparisonFilter;
                const hasComparisonFilter = !isNullOrUndefined(comparisonFilter);

                const plan = report.settings.table.plan;
                const hasPlan = !isNullOrUndefined(plan) && !isNullOrUndefined(plan.name);
                let planName = null;
                if (hasPlan) {
                    planName = plan.name;
                }

                const valueBarSettings: ValueBarCellSettingsWrapper[] = measuresSettings.map((measure) => {
                    const valueBarSetting: ValueBarCellSettingsWrapper = {};
                    if (measure.settings.showPercentageOfTotal) {
                        valueBarSetting.total = data ? this.calcCellValueSettings(data.rows, (row: ReportTableRow) => row.values[measure.code] / data.values[measure.code], 1) : null;
                    }
                    return valueBarSetting;
                });

                const sort = viewSettings.table.sort;

                const tableModel =  new TableModel(data, hasComparisonFilter, this.timeSpan$.getValue(), this.comparisonTimeSpan$.getValue(), hasPlan, planName, 12, availableAttributes, attributes, measures, measuresSettings, valueBarSettings, sort);

                return tableModel;
            })
        ).subscribe(tableModel => {
            this.tableModel$.next(tableModel);

            if (this.isPreview) {
                this.previewAttributeRows = this.getPreviewAttributeRows(tableModel.attributes);
            }
        });
    }

    getDefaultDownloadSelection(): ReportSettings {
        const availableDownloadAttributes = this.availableDownloadAttributes$.getValue();
        const availableDownloadMeasures = this.availableDownloadMeasures$.getValue();

        if (availableDownloadAttributes.length > 0 && availableDownloadMeasures.length > 0) {
            const newViewSettings: ReportSettings = {
                table: {
                    selectedDownloadAttributeCodes: availableDownloadAttributes.map(attr => attr.code),
                    selectedDownloadMeasureCodes: availableDownloadMeasures.map(measure => measure.code)
                }
            };
            return newViewSettings;
        }
        return null;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.keys(changes).find((key) => !changes[key].firstChange)) {
            this.ngOnInit();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private calcCellValueSettings(rows: Array<ReportTableRow>, mapper: Function, totalValue: number): ValueBarCellSettings {
        const max = rows.map((row) => Math.abs(mapper(row))).filter((value) => value !== Infinity && value !== -Infinity).reduce((a, b) => Math.max(a, b), 0);
        const hasNegative = rows.filter(row => mapper(row) < 0).length > 0;
        const hasPostive = rows.filter(row => mapper(row) >= 0).length > 0;

        const range = totalValue !== Infinity && totalValue !== -Infinity ? Math.max(max, totalValue) : max;

        return {
            range: range,
            hideNegative: !hasNegative,
            hidePositive: !hasPostive,
            fullBarVisualization: true
        };
    }

    private getPreviewAttributeRows(attributes: Array<TableAttribute>): Array<Array<TableAttribute>> {
        if (!this.attributes$.getValue().length) {
            return [];
        }

        const previewAttributeRows: Array<Array<TableAttribute>> = [];
        const previewAttributeConfig = this.getPreviewAttributeConfig(attributes);
        let attributesCopy = [];

        // init all attributes with index 1
        for (const attribute of attributes) {
            attribute.index = 1;
        }

        previewAttributeRows.push(attributes);

        while (previewAttributeRows.length < (previewAttributeConfig.reduce((a, b) => a * b, 1))) {
            if (attributesCopy.length) {
                attributesCopy = deepCopy(attributesCopy);
            } else {
                attributesCopy = deepCopy(attributes);
            }

            for (let i = attributesCopy.length - 1; i >= 0; i--) {
                if (attributesCopy[i].index === previewAttributeConfig[i]) {
                    attributesCopy[i].index = 1;
                } else {
                    attributesCopy[i].index++;
                    break;
                }
            }

            previewAttributeRows.push(attributesCopy);
        }

        return previewAttributeRows;
    }

    private getPreviewAttributeConfig(attributes) {
        switch (attributes.length) {
            case 1: return [20];
            case 2: return [5, 4];
            case 3: return [3, 3, 2];
            case 4: return [3, 2, 2, 2];
            case 5: return [2, 2, 2, 2, 1];
        }
        return [];
    }

    public openHelp(measure: ModelMeasure): void {
        this.openHelpEmitter.next(measure);
    }

    public onDrilldown(attribute: { code: string }, value: string): void {
        this.drilldownEventEmitter.emit({ attribute: attribute, value: value });
    }

    public toggleSort(type: 'attribute' | 'measure', index: number) {
        const currentSort = this.viewSettings$.getValue().table.sort;

        const newSort: TableSort = {directionCode: 'DESC'};
        if (type === 'attribute') {
            const newAttribute = this.attributes$.getValue()[index];
            const newAttributeCode = newAttribute.code;
            newSort.attributeCode = newAttributeCode;

            if (currentSort.attributeCode === newAttributeCode && currentSort.directionCode === 'DESC') {
                newSort.directionCode = 'ASC';
            }
        }

        if (type === 'measure') {
            const newMeasure = this.measures$.getValue()[index];
            const newMeasureCode = newMeasure.code;
            newSort.measureCode = newMeasureCode;

            if (currentSort.measureCode === newMeasureCode && currentSort.directionCode === 'DESC') {
                newSort.directionCode = 'ASC';
            }
        }

        const newViewSettings = new ReportSettings();
        newViewSettings.table = { sort: newSort };
        this.viewSettingsEmitter.emit(newViewSettings);

    }

}

class TableAttribute {
    public showBorderLeft: boolean;
    public index: number;
    constructor(public attribute: ModelAttribute, i: number, public width: number) {
        this.showBorderLeft = (i > 0);
    }
}

class TableMeasure {
    public colSpan: number;
    public displayHeader: boolean;
    public showValueBorderLeft: boolean;
    public showComparisonBorderLeft: boolean;
    public showComparisonChangeBorderLeft: boolean;
    public showComparisonChangeSmall: boolean;
    public showPlanValueBorderLeft: boolean;
    public showPlanDeviationBorderLeft: boolean;
    public showPlanDeviationSmall: boolean;
    public showPercentageOfTotalBorderLeft: boolean;

    public width: number;
    public settings: ReportSettingsTableMeasureSettings;

    constructor(public measure: ModelMeasure, measureSettings: ReportSettingsTableMeasure, public valueBarSettings: ValueBarCellSettingsWrapper, i: number, hasComparisonFilter: boolean, hasPlan: boolean) {
        this.settings = measureSettings ? measureSettings.settings : {};

        if (!this.measure.isHidden) {
            this.colSpan = (this.settings.showPercentageOfTotal ? 1 : 0)
                + (this.settings.showValue ? 1 : 0)
                + (this.settings.showComparisonValue && hasComparisonFilter ? 1 : 0)
                + (this.settings.showComparisonChange && hasComparisonFilter ? 2 : 0)
                + (this.settings.showPlanValue && hasPlan ? 1 : 0)
                + (this.settings.showPlanDeviation && hasPlan ? 2 : 0);
        } else {
            this.colSpan = 1;
        }


        this.width = Math.max(this.colSpan, 3) / 2;

        if (i > 0) {
            let hasBorder = false;
            this.showPercentageOfTotalBorderLeft = hasBorder = this.settings.showPercentageOfTotal;
            if (!hasBorder) {
                this.showValueBorderLeft = hasBorder = this.settings.showValue;
            }
            if (!hasBorder) {
                this.showComparisonBorderLeft = hasBorder = this.settings.showComparisonValue;
            }
            if (!hasBorder) {
                this.showComparisonChangeBorderLeft = hasBorder = this.settings.showComparisonChange;
            } else {
                this.showComparisonChangeSmall = true;
            }

            if (!hasBorder) {
                this.showPlanValueBorderLeft = hasBorder = this.settings.showPlanValue;
            }

            if (!hasBorder) {
                this.showPlanDeviationBorderLeft = true;
            } else {
                this.showPlanDeviationSmall = true;
            }
        }

        this.displayHeader = (this.settings.showValue
            || this.settings.showPercentageOfTotal
            || ((this.settings.showComparisonChange || this.settings.showComparisonValue) && hasComparisonFilter)
            || ((this.settings.showPlanDeviation || this.settings.showPlanValue) && hasPlan));
    }
}

class TableModel {
    public attributes: Array<TableAttribute>;
    public measures: Array<TableMeasure>;
    public colCount: number;
    public colWeightCount: number;

    public totalWidth: number;

    public hasSubHeader: boolean;

    constructor(
        public data: ReportTableData,
        public hasComparisonFilter: boolean,
        public timeSpan: DateSpan,
        public comparisonTimeSpan: DateSpan,
        public hasPlan: boolean,
        public planName: string,
        width: number,
        public availableAttributes:  { code: string }[],
        attributes: Array<ModelAttribute>,
        measures: Array<ModelMeasure>,
        measureSettings: Array<ReportSettingsTableMeasure>,
        valueBarSettings: ValueBarCellSettingsWrapper[],
        public sort: TableSort
    ) {

        this.hasPlan = !!planName;

        this.measures = measures.map((measure, index: number) => new TableMeasure(measure, measureSettings[index], valueBarSettings[index], index + attributes.length, hasComparisonFilter, hasPlan));

        const measuresWidth = this.measures.map(measure => measure.width).reduce((a, b) => a + b, 0);

        const attributeWidth = Math.min(3, (width - measuresWidth) / attributes.length);

        this.attributes = attributes.map((a, i) => new TableAttribute(a, i, attributeWidth));

        const numberOfMeasuresColumns = this.measures.map(m => m.colSpan).reduce((a, b) => a + b, 0);

        this.hasSubHeader = this.measures.length !== numberOfMeasuresColumns;

        this.colCount = this.attributes.length + numberOfMeasuresColumns;
        this.totalWidth = measuresWidth + (attributeWidth * attributes.length);
    }

    // length of none restricted measures
    public get noneRestrictedMeasures(): Array<TableMeasure> {
        return this.measures.filter((tableMeasure) => !tableMeasure.measure.isHidden);
    }

}

class ValueBarCellSettingsWrapper {
    total?: ValueBarCellSettings;
}
