import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, HostBinding, ElementRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';

@Component({
    selector: 'mnb-data-provider-selector',
    templateUrl: './mnb-data-provider-selector.component.html',
    styleUrls: ['./mnb-data-provider-selector.component.less']
})
export class MnbDataProviderSelectorComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    private save$ = new Subject<void>();

    public dropdownRight$: Observable<boolean>;

    public isOpen = false;

    private availableFields$ = new BehaviorSubject<(ModelMeasure | ModelAttribute)[]>([]);
    private currentSelectedCodesSet$ = new BehaviorSubject<Set<string>>(new Set());
    private newSelectedCodesSet$ = new BehaviorSubject<Set<string>>(new Set());

    @Input() mode: 'measures' | 'attributes' = 'attributes';

    @Input() limit: number;

    @Input()
    set availableFields(availableFields: (ModelMeasure | ModelAttribute)[]) {
        this.availableFields$.next(availableFields);
    }

    @Input()
    set selectedCodes(selectedCodes: string[]) {
        const newCheckedCodes = new Set<string>();
        for (const code of selectedCodes) {
            newCheckedCodes.add(code);
        }
        this.currentSelectedCodesSet$.next(newCheckedCodes);
        this.newSelectedCodesSet$.next(new Set(newCheckedCodes));
    }

    @Output()
    saveSelectedCodes = new EventEmitter<string[]>();

    @HostBinding('class.filters-row-element') _class1 = true;
    @HostBinding('class.filters-row-elementfilters-row-time-filter') _class2 = true;

    constructor(
        private elRef: ElementRef,
        private mediaService: MediaService,
    ) {
        this.save$.pipe(
            withLatestFrom(this.newSelectedFieldCodes$, this.isSaveable$),
            takeUntil(this.destroy$)
        ).subscribe(([_, newSelectedFieldCodes, isSaveable]) => {
            if (isSaveable) {
                this.saveSelectedCodes.emit(newSelectedFieldCodes);
            }
        });
    }

    public previewString$: Observable<string> = combineLatest([this.availableFields$, this.currentSelectedCodesSet$]).pipe(
        map(([availableFields, currentSelectedCodesSet]) => {
            const states: string[] = [];
            for (const field of availableFields) {
                if (currentSelectedCodesSet.has(field.code)) {
                    states.push(field.name);
                }
            }
            return states.join(', ');
        })
    );

    public fieldSelectorStates$: Observable<FieldSelectorState[]> = combineLatest([this.availableFields$, this.newSelectedCodesSet$]).pipe(
        map(([availableFields, newSelectedCodesSet]) => {
            const states: FieldSelectorState[] = [];
            for (const field of availableFields) {
                const checked = newSelectedCodesSet.has(field.code);
                const fieldState = {
                    code: field.code,
                    name: field.name,
                    checked
                };
                states.push(fieldState);
            }
            return states;
        })
    );

    public isCanExceedLimit$ = this.availableFields$.pipe(
        map(availableFields => {
            return this.limit && availableFields.length > this.limit;
        })
    );

    public isValid$ = this.newSelectedCodesSet$.pipe(
        map(newSelectedCodesSet => {
            const isSelectionEmpty = newSelectedCodesSet.size === 0;
            const isSelectionTooLarge = this.limit && newSelectedCodesSet.size > this.limit;
            return !isSelectionEmpty && !isSelectionTooLarge;
        })
    );

    public isSaveable$ = combineLatest([this.currentSelectedCodesSet$, this.newSelectedCodesSet$, this.isValid$]).pipe(
        map(([currentSelectedCodesSet, newSelectedCodesSet, isValid]) => {
            const isSelectionChanged = !this.isEqualSets(currentSelectedCodesSet, newSelectedCodesSet);
            return isSelectionChanged && isValid;
        })
    );

    public newSelectedFieldCodes$: Observable<string[]> = combineLatest(([this.availableFields$, this.newSelectedCodesSet$])).pipe(
        map(([availableFields, newSelectedCodesSet]) => {
            const selectedFieldCodes: string[] = [];
            for (const field of availableFields) {
                if (newSelectedCodesSet.has(field.code)) {
                    selectedFieldCodes.push(field.code);
                }
            }
            return selectedFieldCodes;
        })
    );

    public save() {
        this.save$.next();
    }

    public toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    public onOutsideClick() {
        this.isOpen = false;
    }

    public toggleChecked(index: number) {
        const clickedField = this.availableFields$.getValue()[index];
        const clickedFieldCode = clickedField.code;
        const checkedFieldCodes = new Set(this.newSelectedCodesSet$.getValue());
        if (checkedFieldCodes.has(clickedFieldCode)) {
            checkedFieldCodes.delete(clickedFieldCode);
        } else {
            checkedFieldCodes.add(clickedFieldCode);
        }
        this.newSelectedCodesSet$.next(checkedFieldCodes);
    }

    private isEqualSets(a: Set<string>, b: Set<string>): boolean {
        return a.size === b.size && Array.from(a).every(value => b.has(value));
    }

    ngOnInit() {
        this.dropdownRight$ = this.mediaService.viewSize$.pipe(
            map(_ => {
                const elementRect = this.elRef.nativeElement.getBoundingClientRect();
                const spaceLeft = elementRect.left;
                const spaceRight = window.innerWidth - elementRect.right;
                return spaceRight < spaceLeft;
            })
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type FieldSelectorState = {
    code: string;
    name: string;
    checked: boolean;
};
