import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';

/*
 *
 * Usage:
 *   number | mnbTimeTenant
 * Example:
 *   {{ 10000566578 | mnbTime }}
 *   formats to: 12:44 (timezone)
*/
@Pipe({ name: 'mnbTimeTenant' })
export class MnbTimeTenantPipe implements PipeTransform {

    constructor(
        private mnbConfigService: MnbConfigService,
        private mnbTimeService: MnbTimeService) {}

    transform(time: number): string {
        const timeZone = this.mnbConfigService.calendarConfig.zoneId;
        const formatTime = this.mnbTimeService.getTimeFormat(true);

        return moment(time).tz(timeZone).format(formatTime) + ' (' + timeZone + ')';
    }

}
