import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, from, combineLatest, BehaviorSubject } from 'rxjs';
import { switchMap, map, take, takeUntil, tap, share, withLatestFrom, debounceTime } from 'rxjs/operators';
import { PortalDataProvider } from '@minubo-portal/modules/api/models/api-data-provider.model';
import { DataProviderContext } from '@minubo-portal/modules/data-providers/resolvers/data-provider.resolver';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { ApiDataService } from '@minubo-portal/modules/api/services/api-data.service';
import { DataProviderData, DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { MnbModelService, ModelMeasure, ModelAttribute } from '@shared-lib/modules/model/services/mnb-model.service';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';


@Component({
    selector: 'data-provider',
    templateUrl: './data-provider.component.html',
    styleUrls: ['./data-provider.component.less'],
})
export class DataProviderComponent implements OnInit, OnDestroy {
    readonly ATTRIBUTES_LIMIT: number = 7;
    readonly MEASURES_LIMIT: number = 15;

    private destroy$ = new Subject<void>();
    private loadAdhocFeed$ = new Subject<void>();

    public loadDone = false;

    private context: DataProviderContext;
    private context$: Observable<DataProviderContext>;
    public availableAttributes$: Observable<{[code: string]: ModelAttribute}>;
    public availableMeasures$: Observable<{[code: string]: ModelMeasure}>;
    public data$ = new BehaviorSubject<DataProviderData>({ 'values': {}, 'compareValues': {}, 'rows': [] });

    // viewSettings state
    public selectedAttributeCodes$ = new BehaviorSubject<string[]>([]);
    public selectedMeasureCodes$ = new BehaviorSubject<string[]>([]);

    public timeFilter$ = new BehaviorSubject<QuerySettingsTimeFilter>(QuerySettingsTimeFilter.getDefault());
    public comparisonFilter$ = new BehaviorSubject<QuerySettingsComparisonFilter>(null);
    public originalCombinedTimeFilters: MnbQuickTimeFilterValue = null;

    public filters$ = new BehaviorSubject<QueryFilter[]>([]);
    public originalFilters: QueryFilter[] = [];

    // derived viewSettings state
    public combinedTimeFilters$: Observable<MnbQuickTimeFilterValue>;
    private viewSettings$: Observable<DataProviderSettings>;

    public viewModel$: Observable<DataProviderDisplayModel>;

    constructor(
        private route: ActivatedRoute,
        private apiDataProviderService: ApiDataProviderService,
        private apiDataService: ApiDataService,
        private modelService: MnbModelService,
        private settingsStore: LocalSettingsStore<DataProviderSettings>,

    ) { }


    ngOnInit(): void {
        this.context$ = this.route.data.pipe(
            map((data: {context: DataProviderContext}) => data.context),
        );

        this.availableAttributes$ = this.context$.pipe(
            switchMap(context => {
                return from(Promise.all(context.dataProvider.settings.availableAttributeCodes.map(attributeCode => {
                    return this.modelService.getAttribute(attributeCode);
                }))).pipe(
                    map(attributes => {
                    const attributeMap: {[code: string]: ModelAttribute} = {};
                    attributes.forEach(attribute => {
                        attributeMap[attribute.code] = attribute;
                    });
                    return attributeMap;
                }));
            })
        );

        this.availableMeasures$ = this.context$.pipe(
            switchMap(context => {
                return from(Promise.all(context.dataProvider.settings.availableMeasureCodes.map(measureCode => {
                    return this.modelService.getMeasure(measureCode);
                }))).pipe(
                    map(measures => {
                    const measureMap: {[code: string]: ModelMeasure} = {};
                    measures.forEach(measure => {
                        measureMap[measure.code] = measure;
                    });
                    return measureMap;
                }));
            })
        );

        this.context$.pipe(
            map(context => {
                this.context = context;

                this.loadInitialFromSettings(context.dataProvider);
                this.loadFromSettingsStore(context.dataProvider);
                this.loadInitialFiltersFromEntity(context.portalEntity);

            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.combinedTimeFilters$ = combineLatest([this.timeFilter$, this.comparisonFilter$]).pipe(
            map(([timeFilter, comparisonFilter]) => {
                return {
                    'timeFilter': timeFilter,
                    'comparisonFilter': comparisonFilter,
                };
            }
        ));

        this.viewSettings$ = combineLatest([this.selectedAttributeCodes$, this.selectedMeasureCodes$, this.timeFilter$, this.comparisonFilter$, this.filters$]).pipe(
            map(([selectedAttributeCodes, selectedMeasureCodes, timeFilter, comparisonFilter, filters]) => {
                const viewSettings: DataProviderSettings = {
                    selectedAttributeCodes, selectedMeasureCodes, timeFilter, comparisonFilter, filters
                };
                return viewSettings;
            })
        );

        // automatically load data when viewsettings change
        this.viewSettings$
            .pipe(
                withLatestFrom(this.context$),
                tap(() => this.loadDone = false),
                debounceTime(50),
                switchMap(([viewSettings, context]) => this.apiDataProviderService.loadDataProviderData(context.dataProviderId, viewSettings)),
                share(),
                takeUntil(this.destroy$))
            .subscribe(data => {
                this.data$.next(data);
                this.loadDone = true;
            });

        this.viewModel$ = combineLatest([this.viewSettings$, this.combinedTimeFilters$, this.data$, this.availableAttributes$, this.availableMeasures$]).pipe(
            map(([viewsettings, combinedTimeFilters, data, availableAttributes, availableMeasures]) => {
                const hasComparisonFilter = !!combinedTimeFilters.comparisonFilter;
                const selectedAttributeCodes = viewsettings.selectedAttributeCodes;
                const selectedMeasureCodes = viewsettings.selectedMeasureCodes;
                const filters = viewsettings.filters;
                const availableAttributesArray = this.context.dataProvider.settings.availableAttributeCodes.map(code => availableAttributes[code]);
                const availableMeasuresArray = this.context.dataProvider.settings.availableMeasureCodes.map(code => availableMeasures[code]);
                const model: DataProviderDisplayModel = {
                    filters, combinedTimeFilters, hasComparisonFilter, selectedAttributeCodes, selectedMeasureCodes, availableAttributes, availableMeasures, availableAttributesArray, availableMeasuresArray, data
                };
                return model;
            })
        );

        this.loadAdhocFeed$.pipe(
            withLatestFrom(this.viewSettings$),
            map(([_, viewSettings]) => {
                this.apiDataProviderService.loadReportAdhocFeedUrl(this.context.dataProviderId, viewSettings)
                .subscribe(data => window.open(data.url, '_blank'));
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private loadInitialFromSettings(dataProvider: PortalDataProvider) {
        this.selectedAttributeCodes$.next(dataProvider.settings.availableAttributeCodes.slice(0, 1));
        this.selectedMeasureCodes$.next(dataProvider.settings.availableMeasureCodes.slice(0, 1));

        this.timeFilter$.next(deepCopy(dataProvider.settings.timeFilter));
        this.comparisonFilter$.next(deepCopy(dataProvider.settings.comparisonFilter));
        this.originalCombinedTimeFilters = {
            'timeFilter': dataProvider.settings.timeFilter,
            'comparisonFilter': dataProvider.settings.comparisonFilter,
        };
    }

    private loadInitialFiltersFromEntity(portalEntity: PortalEntity) {
        if (this.filters$.getValue().length === 0) {
            this.filters$.next(portalEntity.settings.filters);
        }
        this.originalFilters = portalEntity.settings.filters;
        this.originalFilters.forEach(filter => {
            filter.values = filter.values || [];
        });

        // validate current filters
        const currentFilters = this.filters$.getValue();
        if (!this.validateFilters(currentFilters, this.originalFilters)) {
            this.filters$.next(this.originalFilters);
        }
    }

    public loadFilterValues = (attributeCode: string, filterSearch?: string): Promise<{values: string[], hasMore?: boolean}> => {
        return this.apiDataService.loadAttributeData(attributeCode, filterSearch).toPromise();
    }

    public onTimeFilterChange(combinedTimeFilters: MnbQuickTimeFilterValue) {
        this.timeFilter$.next(combinedTimeFilters.timeFilter);
        this.comparisonFilter$.next(combinedTimeFilters.comparisonFilter);
        this.saveToSettingsStore();
    }

    public onFilterChange(filter: QueryFilter, index) {
        const currentFilters = this.filters$.getValue();
        currentFilters[index] = filter;
        if (this.validateFilters(currentFilters, this.originalFilters)) {
            this.filters$.next(currentFilters);
        } else {
            this.filters$.next(this.originalFilters);
        }
        this.saveToSettingsStore();
    }

    public onSaveSelectedAttributeCodes(selectedAttributeCodes: string[]) {
        if (selectedAttributeCodes.length > 0) {
            this.selectedAttributeCodes$.next(selectedAttributeCodes);
            this.saveToSettingsStore();
        }
    }

    public onSaveSelectedMeasureCodes(selectedMeasureCodes: string[]) {
        if (selectedMeasureCodes.length > 0) {
            this.selectedMeasureCodes$.next(selectedMeasureCodes);
            this.saveToSettingsStore();
        }
    }

    public loadAdhocFeed() {
        this.loadAdhocFeed$.next();
    }

    private validateFields(selectedFieldCodes: string[], availableFields: string[], limit: number) {
        if (selectedFieldCodes.length > limit) {
            return false;
        }
        return selectedFieldCodes.every(selectedFieldCode => availableFields.includes(selectedFieldCode));
    }

    private validateFilters(currentFilters: QueryFilter[], originalFilters: QueryFilter[]): boolean {
        const isEqualLength = currentFilters.length === originalFilters.length;
        let isSameCodes = false;
        if (isEqualLength) {
            isSameCodes = currentFilters.every((currentFilter, index) => {
                return currentFilter.attributeCode === originalFilters[index].attributeCode;
            });
        }
        return isEqualLength && isSameCodes;
    }

    private saveToSettingsStore() {
        this.viewSettings$.pipe(
            take(1)
        ).subscribe(viewSettings => {
            this.settingsStore.store('dataProvider', this.context.dataProviderId, viewSettings);
        });
    }

    private loadFromSettingsStore(dataProvider: PortalDataProvider): void {
        const viewSettings = this.settingsStore.load('dataProvider', dataProvider.id);
        if (!viewSettings) {
            return;
        }
        if (viewSettings.timeFilter) {
            this.timeFilter$.next(viewSettings.timeFilter);
        }
        if (viewSettings.comparisonFilter || viewSettings.comparisonFilter === null) {
            this.comparisonFilter$.next(viewSettings.comparisonFilter);
        }
        if (viewSettings.filters) {
            this.filters$.next(viewSettings.filters);
        }
        if (viewSettings.selectedAttributeCodes) {
            if (this.validateFields(viewSettings.selectedAttributeCodes, this.context.dataProvider.settings.availableAttributeCodes, this.ATTRIBUTES_LIMIT)) {
                this.selectedAttributeCodes$.next(viewSettings.selectedAttributeCodes);
            } else {
                this.selectedAttributeCodes$.next(this.context.dataProvider.settings.availableAttributeCodes.slice(0, 1));
            }
        }
        if (viewSettings.selectedMeasureCodes) {
            if (this.validateFields(viewSettings.selectedMeasureCodes, this.context.dataProvider.settings.availableMeasureCodes, this.MEASURES_LIMIT)) {
                this.selectedMeasureCodes$.next(viewSettings.selectedMeasureCodes);
            } else {
                this.selectedMeasureCodes$.next(this.context.dataProvider.settings.availableMeasureCodes.slice(0, 1));
            }
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type DataProviderDisplayModel = {
    filters: QueryFilter[];
    combinedTimeFilters: MnbQuickTimeFilterValue;
    hasComparisonFilter: boolean;
    selectedAttributeCodes: string[];
    selectedMeasureCodes: string[];
    availableAttributes: {[code: string]: ModelAttribute};
    availableMeasures: {[code: string]: ModelMeasure};
    availableAttributesArray: ModelAttribute[];
    availableMeasuresArray: ModelMeasure[];
    data: DataProviderData;
};
