import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import {RoutingModule} from './modules/routing/routing.module';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { ApiModule } from './modules/api/api.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MnbModelModule } from '@shared-lib/modules/model/mnb-model.module';
import { ApiModelLoader } from './modules/api/providers/api-model-loader.provider';
import { MnbConfigModule } from '@shared-lib/modules/config/mnb-config.module';
import { ApiConfigProvider } from './modules/api/providers/api-config-provider.provider';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import {ServiceModule} from '@minubo-portal/services/service.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function TranslateHttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, '/i18n/lang-', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RoutingModule,
        MnbLibModule,
        ApiModule,
        ServiceModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MnbModelModule.forRoot({loader: ApiModelLoader}),
        MnbConfigModule.forRoot({provider: ApiConfigProvider})
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
        // We're not using the mnb-translate module from the suite for now because of too many old dependencies.
        provide: LOCALE_ID,
        deps: [TranslateService],
        useFactory: (translateService: TranslateService) => {
            return translateService.currentLang === 'de' ? 'de-DE' : 'en-EN';
        }
    }],
    bootstrap: [AppComponent]
})
export class AppModule {

}
