export type PortalEtlStatus = {
    statusCode: EtlStatusCode;
    finishingDateTime: number;
    targetDateTime: number;
};

export enum EtlStatusCode {
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    NOT_STARTED = 'NOT_STARTED',
    ON_HOLD = 'ON_HOLD',
    NO_DAILY_UPDATE = 'NO_DAILY_UPDATE'
}
