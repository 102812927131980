import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalDashboard, PortalDashboardWidgetData, PortalDashboardWidgetDetailData } from '../models/api-dashboard.model';
import { catchError } from 'rxjs/operators';
import { environment } from 'projects/minubo-portal/src/environments/environment';
import { NEVER, Observable, OperatorFunction } from 'rxjs';
import { PortalSession } from '../models/api-login.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { PortalReport, PortalReportData } from '@minubo-portal/modules/api/models/api-report.model';
import { PortalDataProvider, PortalDataProviderData } from '@minubo-portal/modules/api/models/api-data-provider.model';
import { TrackingEvent } from '@shared-lib/modules/core/model/tracking.model';

@Injectable()
export class ApiService {

    private errorHandler: OperatorFunction<unknown, any>;

    constructor(
        private http: HttpClient
    ) {
        this.errorHandler = catchError((error: HttpErrorResponse): Observable<any> => {
            if (error.status === 403) {
                // logged out?
            }
            // hint: forward the error?
            return NEVER;
        });
    }

    public query<T>(resource: Resource<T>, args?: { [name: string]: string | number | boolean }): Observable<T[]> {
        // returns an array of objects
        return this.http.get<T[]>(this.buildUrlFromResource(resource, args), {
            withCredentials: true
        }).pipe(this.errorHandler);
    }

    public get<T, O>(resource: Resource<T>, args?: { [name: string]: string | number | boolean }): Observable<O> {
        // returns a single object
        return this.http.get<T>(this.buildUrlFromResource(resource, args), {
            withCredentials: true
        }).pipe(this.errorHandler);
    }

    public save<T, O>(resource: Resource<T>, object: T, args?: { [name: string]: string | number | boolean }): Observable<O> {
        return this.post(resource, object, args);
    }

    public post<T, O>(resource: Resource<T>, object: any, args?: { [name: string]: string | number | boolean }): Observable<O> {
        return this.http.post<T>(this.buildUrlFromResource(resource, args), object, { withCredentials: true }).pipe(this.errorHandler);
    }

    public buildApiUrl(path: string, args?: any): string {
        return this.buildUrl(environment.apiUrl, ResourcePathPart.parsePath(path), args);
    }

    private buildUrlFromResource(resource: Resource<any>, args: { [name: string]: string | number | boolean }): string {
        return this.buildUrl(resource.host, resource.pathParts, args);
    }

    private buildUrl(host: string, pathParts: ResourcePathPart[], args: { [name: string]: string | number | boolean }): string {
        let url = host;
        const unusedArgs = Object.assign({}, args);

        for (const part of pathParts) {
            if (part.isVar) {
                if (args && args.hasOwnProperty(part.arg)) {
                    delete unusedArgs[part.arg];
                    url = url + '/' + (args[part.arg] ? args[part.arg] : '');
                }
            } else {
                url = url + '/' + part.path;
            }
        }

        Object.keys(unusedArgs).forEach((argKey, i) => {
            if (i === 0) {
                url = url + '?';
            } else {
                url = url + '&';
            }
            // hint: no eascaping so far. here would be the place...
            url = url + argKey + '=' + encodeURIComponent(unusedArgs[argKey]);
        });
        return url;
    }


}

export class ResourcePathPart {

    isVar: string;
    arg: string;
    path: string;


    public static parsePath(path: string): ResourcePathPart[] {
        const pathParts = [];
        for (const part of path.split('/')) {
            if (part.startsWith(':')) {
                pathParts.push({
                    isVar: true,
                    arg: part.substring(1)
                });
            } else if (part.length) {
                pathParts.push({
                    path: part
                });
            }
        }
        return pathParts;
    }
}

export class Resource<E> {

    public pathParts: ResourcePathPart[];

    constructor(
        public readonly host: string,
        public readonly path: string
    ) {
        this.pathParts = ResourcePathPart.parsePath(path);
    }
}

export class Resources {
    public static PORTAL_ENTITY = new Resource<any>(environment.apiUrl, 'v3/portal/entities/:typeCode/:id');
    public static DASHBOARD = new Resource<PortalDashboard>(environment.apiUrl, 'v3/dashboards/:id');
    public static DASHBOARD_WIDGET_DATA = new Resource<PortalDashboardWidgetData>(environment.dataUrl, 'v3/dashboards/:dashboardId/widgets/:widgetId/data');
    public static DASHBOARD_WIDGET_DETAIL_DATA = new Resource<PortalDashboardWidgetDetailData>(environment.dataUrl, 'v3/dashboards/:dashboardId/widgets/:widgetId/detailData');
    public static SESSION_LANGUAGE = new Resource<PortalSession>(environment.apiUrl, 'v3/session/language');
    public static SESSION_PARTNER = new Resource<PortalSession>(environment.apiUrl, 'v3/session/partner');
    public static MODEL_MEASURE = new Resource<ModelMeasure>(environment.apiUrl, 'v3/model/measures');
    public static MODEL_ATTRIBUTE = new Resource<ModelAttribute>(environment.apiUrl, 'v3/model/attributes');
    public static DATA_ATTRIBUTE_VALUES = new Resource<any>(environment.apiUrl, 'v3/data/attributes/:code/values');
    public static REPORT = new Resource<PortalReport>(environment.apiUrl, 'v3/reports/:id');
    public static REPORT_DATA = new Resource<PortalReportData>(environment.dataUrl, 'v3/reports/:id/data');
    public static REPORT_ADHOC_FEED = new Resource<PortalReport>(environment.apiUrl, 'v3/reports/:id/adhocFeed');
    public static DATA_PROVIDER = new Resource<PortalDataProvider>(environment.apiUrl, 'v3/dataProviders/:id');
    public static DATA_PROVIDER_DATA = new Resource<PortalDataProviderData>(environment.dataUrl, 'v3/dataProviders/:id/data');
    public static DATA_PROVIDER_ADHOC_FEED = new Resource<PortalDataProviderData>(environment.dataUrl, 'v3/dataProviders/:id/adhocFeed');
    public static TRACKING = new Resource<TrackingEvent>(environment.apiUrl, 'v3/tracking');
    public static ETL_TODAY = new Resource<any>(environment.apiUrl, 'v3/etl/today');
}
