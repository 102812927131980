import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';
import { forkJoin, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { PortalDataProvider } from '@minubo-portal/modules/api/models/api-data-provider.model';
import { DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';
import { ApiPortalService } from '@minubo-portal/modules/api/services/api-portal.service';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';


@Injectable()
export class DataProviderContextResolver implements Resolve<DataProviderContext> {


    constructor(
        private apiDataProviderService: ApiDataProviderService,
        private apiPortalService: ApiPortalService,
        private trackingService: ApiTrackingService,
        private settingsStore: LocalSettingsStore<DataProviderSettings>,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<DataProviderContext> {
        const dataProviderId = route.params['dataProviderId'];

        const localSettings = this.settingsStore.load('dataProvider', dataProviderId);

        const dataProviderObservable = this.apiDataProviderService.loadDataProvider(dataProviderId);
        const portalEntityObservable = this.apiPortalService.loadEntity(EntityType.DATA_PROVIDER, dataProviderId);

        const result = forkJoin([dataProviderObservable, portalEntityObservable]).pipe(
            map(([dataProvider, portalEntity]) => {
                (<AppHeader>route.data.header).title = dataProvider.title;
                return {
                    dataProviderId: dataProviderId,
                    dataProvider: dataProvider,
                    portalEntity: portalEntity,
                    localSettings: localSettings
                };
            }),
            share()
        );
        result.subscribe(dataProvider => {
            const parameters = {
                'dataProviderId': dataProvider.dataProvider.id,
                'dataProviderTitle': dataProvider.dataProvider.title,
            };
            this.trackingService.tracking('dataProvider', 'view', parameters);
        });
        return result;
    }
}

export type DataProviderContext = {
    dataProviderId: number;
    dataProvider: PortalDataProvider;
    portalEntity: PortalEntity;
    localSettings: DataProviderSettings | null;
};
