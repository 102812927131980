import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { ReportData, ReportSettings, ReportSettingsDrilldownStep } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject } from 'rxjs';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { DrilldownFilterContext, mapToDrilldownContext, MnbReportDrilldownStepModel } from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import {
    MnbReportsEntityDrilldownDisplayDrilldownMeasureModel,
} from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { TraceClassDecorator } from '@sentry/angular';



@TraceClassDecorator()
@Component({
    selector: 'reports-entity-drilldown',
    templateUrl: './reports-entity-drilldown.component.html'
})
export class ReportsEntityDrilldownComponent implements OnInit {

    public form: FormGroup;

    public viewSettings$ = new BehaviorSubject<ReportSettings>(null);

    public report: PortalReport;
    public data: ReportData;
    public dataViewSettings: ReportSettings;

    public availableMeasures: ModelMeasure[] = [];

    /**
     * @deprecated
     */
    public drilldownEntity: MnbReportDrilldownStepModel;

    public drilldownContextList: DrilldownFilterContext[] = [];

    ngOnInit(): void {
        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'comparisonFilter': new FormControl(null),
            'filters': new FormControl([]),
            'selectedMeasureCodes': new FormControl([])
        });

    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;
        if (!this.report) {
            this.report = event.report;
        }

        this.form.get('timeFilter').setValue(settings.entityDrilldown.timeFilter);
        this.form.get('comparisonFilter').setValue(settings.entityDrilldown.comparisonFilter);
        this.form.get('filters').setValue(settings.entityDrilldown.filters || []);
        if (settings.entityDrilldown.selectedMeasureCodes && settings.entityDrilldown.selectedMeasureCodes.length > 0) {
            this.form.get('selectedMeasureCodes').setValue(settings.entityDrilldown.selectedMeasureCodes);
        }
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        const viewSettings = new ReportSettings();
        viewSettings.entityDrilldown = {
            timeFilter: formValue.timeFilter,
            comparisonFilter: formValue.comparisonFilter,
            filters: formValue.filters,
            selectedMeasureCodes: formValue.selectedMeasureCodes,
            drilldownPathList: this.report.settings.entityDrilldown.drilldownPathList,
            measureSets: this.report.settings.entityDrilldown.measureSets,
            selectedDrilldownFilters: this.viewSettings$.value ? this.viewSettings$.value.entityDrilldown.selectedDrilldownFilters : [],
        };
        this.viewSettings$.next(viewSettings);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
        this.dataViewSettings = event.viewSettings;
        if (this.drilldownEntity && event.data) {
            this.drilldownEntity.attributes = {...this.drilldownEntity.attributes, ...event.data.entityDrilldown.entity.attributes};
        }
    }

    onSaveSelectedMeasureCodes(selectedMeasureCodes: string[]) {
        if (selectedMeasureCodes.length > 0) {
            this.form.get('selectedMeasureCodes').setValue(selectedMeasureCodes);
        }
    }

    onAvailableMeasures(availableMeasures: MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[]) {
        this.availableMeasures = availableMeasures.map(drilldownMeasure => drilldownMeasure.measure);
    }

    onDrilldown(step: ReportSettingsDrilldownStep) {
        const newFilters: QueryFilter[] = [];

        for (const attr in step.keyValues) {
            if (!step.keyValues.hasOwnProperty(attr)) {
                continue; // Linting expects this guard-condition when using for-in loops
            }
            const newDrilldownFilter: QueryFilter = {
                typeCode: 'equals',
                attributeCode: attr,
                values: [step.keyValues[attr]]
            };
            newFilters.push(newDrilldownFilter);
        }

        const viewSettingsDrilldown = this.viewSettings$.value.entityDrilldown;
        const currentDrilldownFilters = !!viewSettingsDrilldown.selectedDrilldownFilters
            ? [...this.viewSettings$.value.entityDrilldown.selectedDrilldownFilters]
            : [];

        // Updating drilldownContext
        this.drilldownContextList = [
            ...this.drilldownContextList,
            ...mapToDrilldownContext(newFilters, step.keyValues, step.nameValues)
        ];

        // Updating viewSettings
        this.viewSettings$.next({
            entityDrilldown: {
                ...viewSettingsDrilldown,
                selectedDrilldownFilters: [...currentDrilldownFilters, ...newFilters]
            }
        });
    }

    onDrillup(filter: {attributeCode: string}) {
        const newFilters = [];
        if (!filter) {
            this.drilldownContextList = [];
        } else if (!!filter) {
            const currentSettings = this.viewSettings$.value.entityDrilldown;
            const removedFilterIdx = currentSettings.selectedDrilldownFilters
                .findIndex(selected => selected.attributeCode === filter.attributeCode);
            // 'Clicked on' should be included, thus idx+1
            newFilters.push(...currentSettings.selectedDrilldownFilters.slice(0, removedFilterIdx + 1));
            this.drilldownContextList = this.drilldownContextList.slice(0, removedFilterIdx + 1);
        }

        this.viewSettings$.next({
            entityDrilldown: {
                ...this.viewSettings$.value.entityDrilldown,
                selectedDrilldownFilters: newFilters
            }
        });
    }

}
