import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { filterTypeOptions } from '@shared-lib/modules/queries/model/query-filter.model';
import { FilterService } from '@shared-lib/modules/queries/services/filter-service';
import deepEqual from 'deep-equal';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';

@Component({
    templateUrl: './mnb-quick-filter.component.html',
    selector: 'mnb-quick-filter'
})
export class MnbQuickFilterComponent implements OnInit, AfterViewInit {

    @Input() filter: QueryFilter;
    @Input() title: string;
    @Input() originalFilter: QueryFilter;
    @Input() valueLoader: (attributeCode: string, filterSearch?: string) => Promise<{values: string[], hasMore?: boolean}>;
    @Input() dropdownRight: boolean;

    @Output() filterChange = new EventEmitter<QueryFilter>();

    public model: QuickFilterValueSelectorModel;
    public valuesLabel: string;
    public suggestionsLoadingStatus: MnbBusyStatus = new MnbBusyStatus();
    public filterValueInput: string;
    public showResetButton: boolean;
    public dropdownRight$: Observable<boolean>;

    private searchInputSubject: Subject<string> = new Subject();
    private searchTimeout = 250;

    @ViewChild('container', { static: true }) container: ElementRef;

    constructor(
        private filterService: FilterService,
        private elRef: ElementRef,
        private mediaService: MediaService,
    ) {}

    public ngOnInit(): void {
        this.valueLoader(this.filter.attributeCode).then(filterAttributeValues => {
            filterAttributeValues.values = filterAttributeValues.values.filter(filterAttributeValue => this.filter.values.indexOf(filterAttributeValue) === -1);

            this.model = new QuickFilterValueSelectorModel(
                this.filter.values,
                filterAttributeValues,
                this.filter.typeCode,
                this.filter.attributeCode
            );

            this.showResetButton = !deepEqual(this.originalFilter, this.model.editForm.value);

            this.model.changes.subscribe(() => {
                const filter = this.model.editForm.value;
                this.showResetButton = !deepEqual(this.originalFilter, filter);
                this.filterChange.emit(filter);
                this.valuesLabel = this.filterService.createFilterLabel(filter, this.container.nativeElement);
            });
        });

        this.searchInputSubject.pipe(debounceTime(this.searchTimeout)).subscribe((searchInput) => {
            this.updateModelFilterAttributeValues(searchInput);
        });

        this.suggestionsLoadingStatus.done();

        this.valuesLabel = this.filterService.createFilterLabel(this.filter, this.container.nativeElement);
    }

    public ngAfterViewInit() {
        this.dropdownRight$ = this.mediaService.viewSize$.pipe(
            startWith(null),
            map(_ => {
                const elementRect = this.elRef.nativeElement.getBoundingClientRect();
                const spaceLeft = elementRect.left;
                const spaceRight = window.innerWidth - elementRect.right;
                return spaceRight < spaceLeft;
            })
        );
    }

    public onClick() {
        if (this.model) {
            this.model.isOpened = !this.model.isOpened;
        }
    }

    public onOutsideClick() {
        if (this.model) {
            this.model.isOpened = false;
        }
    }

    public onSearchInputModelChange(searchInput: string) {
        this.searchInputSubject.next(searchInput);
    }

    private updateModelFilterAttributeValues(searchInput: string) {
        this.suggestionsLoadingStatus = new MnbBusyStatus();
        this.valueLoader(this.filter.attributeCode, searchInput).then(filterAttributeValues => {
            filterAttributeValues.values = filterAttributeValues.values.filter(filterAttributeValue => this.filter.values.indexOf(filterAttributeValue) === -1);
            this.model.filterAttributeValues = filterAttributeValues;
            this.suggestionsLoadingStatus.done();
        });
    }

    public onFilterAttributeValueSelect(index: number): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        formValues.push(this.model.filterAttributeValues.values.splice(index, 1)[0]);
        this.model.editForm.controls.values.setValue(formValues);
    }

    public onFilterValueRemove(index: number): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.updateAttributeValues(formValues.splice(index, 1).concat(this.model.filterAttributeValues.values));
        this.model.editForm.controls.values.setValue(formValues);
    }

    public useFilterValueOnly(index: number) {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.editForm.controls.values.setValue([formValues[index]]);
        formValues.splice(index, 1);
        this.model.updateAttributeValues(formValues.concat(this.model.filterAttributeValues.values));
    }

    public useFilterAttributeValueOnly(index: number) {
        const usedValues: string[] = [...this.model.editForm.controls.values.value];
        const values = this.model.filterAttributeValues.values;
        this.model.editForm.controls.values.setValue([values[index]]);
        this.model.updateAttributeValues(values.slice(0, index).concat(values.slice(index + 1)).concat(usedValues));
    }

    public clearFilterValues(): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.editForm.controls.values.setValue([]);
        this.model.updateAttributeValues(formValues.concat(this.model.filterAttributeValues.values));
    }

    public addFilterValue(): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        formValues.push(this.filterValueInput);
        this.model.editForm.controls.values.setValue(formValues);
        this.filterValueInput = '';
    }

    public onReset(e: Event): void {
        e.stopPropagation();
        this.resetFilter();
    }

    private resetFilter(): void {
        this.model.editForm.controls.attributeCode.setValue(this.originalFilter.attributeCode, { emitEvent: false });
        this.model.editForm.controls.values.setValue(this.originalFilter.values, { emitEvent: false });
        this.model.editForm.controls.typeCode.setValue(this.originalFilter.typeCode);

        this.updateModelFilterAttributeValues('');
    }
}

export class QuickFilterValueSelectorModel {
    public changes = new EventEmitter();
    public isOpened: boolean;
    public filterTypeOptions;
    public isEqualsFilterType;
    public editForm: FormGroup;

    constructor(
        public filterValues: Array<string>,
        public filterAttributeValues: { hasMore?: boolean, values: Array<string> },
        public filterTypeCode: string,
        public filterAttributeCode: string
    ) {
        this.filterTypeOptions = filterTypeOptions;
        this.isEqualsFilterType = filterTypeOptions.find(filterTypeOption => filterTypeOption.code === filterTypeCode).isEqualsFilterType;

        this.editForm = new FormGroup({
            attributeCode: new FormControl(filterAttributeCode),
            typeCode: this.createControl(this.filterTypeCode),
            values: this.createControl(this.filterValues)
        });

        this.editForm.controls.typeCode.valueChanges.subscribe((typeCode) => {
            this.isEqualsFilterType = filterTypeOptions.find(filterTypeOption => filterTypeOption.code === typeCode).isEqualsFilterType;
        });
    }

    private createControl(value: any): FormControl {
        const control = new FormControl(value);
        control.valueChanges.subscribe(() => setTimeout(() => this.changes.emit()));
        return control;
    }

    public updateAttributeValues(values: string[]) {
        this.filterAttributeValues.values = [...values].sort((a, b) => a.localeCompare(b));
    }

}
