import { Component, OnInit } from '@angular/core';
import { PortalDashboard, PortalDashboardWidgetData } from '@minubo-portal/modules/api/models/api-dashboard.model';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { PortalDataProvider } from '@minubo-portal/modules/api/models/api-data-provider.model';
import { ApiDashboardService } from '@minubo-portal/modules/api/services/api-dashboard.service';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    templateUrl: './routing-overview-page.component.html'
})
export class RoutingOverviewPageComponent implements OnInit {

    constructor(
        private dashboardService: ApiDashboardService,
        private reportService: ApiReportService,
        private dataProviderService: ApiDataProviderService,
    ) {
    }

    private dashboards$: Observable<PortalDashboard[]>;
    private reports$: Observable<PortalReport[]>;
    private dataProviders$: Observable<PortalDataProvider[]>;
    public combinedMenuItems$: Observable<MenuItem[]>;

    private static sortByTitle<T extends {title: string}>(arr: T[]): void {
        arr.sort((a, b) => (a.title || '').localeCompare((b.title || '')));
    }

    ngOnInit() {
        this.dashboards$ = this.dashboardService.loadDashboards();
        this.reports$ = this.reportService.loadReports();
        this.dataProviders$ = this.dataProviderService.loadDataProviders();

        this.combinedMenuItems$ = combineLatest([this.dashboards$, this.reports$, this.dataProviders$]).pipe(
            map(([dashboards, reports, dataProviders]) => {
                const combinedMenuItems: MenuItem[] = [];

                dashboards.forEach(dashboard => {
                    combinedMenuItems.push({title: dashboard.title, description:dashboard.description, link: `/dashboards/${dashboard.id}`});
                });

                reports.forEach(report => {
                    combinedMenuItems.push({title: report.title, description: report.description, link: `/reports/${report.id}`});
                });

                dataProviders.forEach(dataProvider => {
                    combinedMenuItems.push({title: dataProvider.title, description: dataProvider.description, link: `/dataProviders/${dataProvider.id}`});
                });

                RoutingOverviewPageComponent.sortByTitle(combinedMenuItems);
                return combinedMenuItems;
            })
        );
    }
}

export type MenuItem = {
    title: string;
    description: string;
    link: string;
};
