import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { ReportsReportPageComponent, ReportsReportPageContextResolver } from '@minubo-portal/modules/reports/pages/report/reports-report-page.component';
import { PortalReportDataService, ReportsReportService } from '@minubo-portal/modules/reports/services/reports.service';
import { TranslateModule } from '@ngx-translate/core';
import { MnbFiltersModule } from '@shared-lib/modules/filters/mnb-filters.module';
import { MnbQueriesModule } from '@shared-lib/modules/queries/mnb-queries.module';
import { MnbReportsModule } from '@shared-lib/modules/reports/mnb-reports.module';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { ReportsEntityDrilldownComponent } from './components/entity-drilldown/reports-entity-drilldown.component';
import { ReportsTemplateComponent } from './components/template/reports-template.component';
import { ReportsInventoryComponent } from './components/inventory/reports-inventory.component';
import { ReportsRankComparisonComponent } from '@minubo-portal/modules/reports/components/rank-comparison/reports-rank-comparison.component';
import { ReportsStoreAreasComponent } from '@minubo-portal/modules/reports/components/store-areas/reports-store-areas.component';
import { ReportsExperienceGroupComponent } from '@minubo-portal/modules/reports/components/experience-group/reports-experience-group.component';
import { ReportsTableComponent } from '@minubo-portal/modules/reports/components/table/reports-table.component';
import { REPORT_DATA__SERVICE_TOKEN } from '@shared-lib/modules/reports/components/report-data.service';
import { UtilsModule } from '@minubo-portal/utils/utils.module';

@NgModule({
    declarations: [
        ReportsReportPageComponent,
        ReportsTemplateComponent,
        ReportsEntityDrilldownComponent,
        ReportsInventoryComponent,
        ReportsRankComparisonComponent,
        ReportsStoreAreasComponent,
        ReportsExperienceGroupComponent,
        ReportsTableComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MnbLibModule,
        UtilsModule,
        RouterModule.forChild([{
            path: ':reportId',
            resolve: {
                context: ReportsReportPageContextResolver
            },
            data: {
                header: <AppHeader>{
                    title: ''
                }
            },
            children: [{
                path: '',
                component: ReportsReportPageComponent,
            }]
        }]),
        TranslateModule,
        MnbFiltersModule,
        MnbQueriesModule,
        MnbReportsModule
    ],
    exports: [],
    providers: [
        ReportsReportService,
        ReportsReportPageContextResolver,
        { provide: REPORT_DATA__SERVICE_TOKEN, useClass: PortalReportDataService }
    ]
})
export class ReportsModule {

}
