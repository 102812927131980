import { Component, EventEmitter, Input, OnDestroy, Output, HostBinding } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';

@Component({
    selector: 'mnb-reports-measure-selector',
    templateUrl: './mnb-reports-measure-selector.component.html',
    styleUrls: ['./mnb-reports-measure-selector.component.less']
})
export class MnbReportsMeasureSelectorComponent implements OnDestroy {

    private destroy$ = new Subject<void>();

    save$ = new Subject<void>();

    isOpen = false;

    availableMeasures$ = new BehaviorSubject<ModelMeasure[]>([]);
    currentSelectedMeasureCodesSet$ = new BehaviorSubject<Set<string>>(new Set());
    newSelectedMeasureCodesSet$ = new BehaviorSubject<Set<string>>(new Set());

    @Input()
    initialMeasureSelectionCount = 15;

    @Input()
    set availableMeasures(availableMeasures: ModelMeasure[]) {
        if (Array.isArray(availableMeasures)) {
            this.availableMeasures$.next(availableMeasures);
        }
    }

    @Input()
    set selectedMeasureCodes(selectedMeasureCodes: string[]) {
        const newCheckedMeasureCodes = new Set<string>();
        for (const measureCode of selectedMeasureCodes) {
            newCheckedMeasureCodes.add(measureCode);
        }
        this.currentSelectedMeasureCodesSet$.next(newCheckedMeasureCodes);
        this.newSelectedMeasureCodesSet$.next(new Set(newCheckedMeasureCodes));
    }

    @HostBinding('class.filters-row-element') _class1 = true;
    @HostBinding('class.filters-row-elementfilters-row-time-filter') _class2 = true;

    previewString$: Observable<string> = combineLatest([this.availableMeasures$, this.currentSelectedMeasureCodesSet$]).pipe(
        map(([availableMeasures, currentSelectedMeasureCodesSet]) => {
            const states: string[] = [];
            for (const measure of availableMeasures) {
                if (currentSelectedMeasureCodesSet.has(measure.code)) {
                    states.push(measure.name);
                }
            }
            return states.join(', ');
        })
    );

    measureSelectorStates$: Observable<MeasureSelectorState[]> = combineLatest([this.availableMeasures$, this.newSelectedMeasureCodesSet$]).pipe(
        map(([availableMeasures, newSelectedMeasureCodes]) => {
            const states: MeasureSelectorState[] = [];
            for (const measure of availableMeasures) {
                const checked = newSelectedMeasureCodes.has(measure.code);
                const measureState = {
                    code: measure.code,
                    name: measure.name,
                    checked
                };
                states.push(measureState);
            }
            return states;
        })
    );

    isAllMeasuresSelected$ = combineLatest([this.availableMeasures$, this.newSelectedMeasureCodesSet$]).pipe(
        map(([availableMeasures, newSelectedMeasureCodesSet]) => {
            return availableMeasures.length === newSelectedMeasureCodesSet.size;
        })
    );

    isSaveable$ = combineLatest([this.currentSelectedMeasureCodesSet$, this.newSelectedMeasureCodesSet$]).pipe(
        map(([currentSelectedMeasureCodesSet, newSelectedMeasureCodesSet]) => {
            const selectionChanged = !this.isEqualSets(currentSelectedMeasureCodesSet, newSelectedMeasureCodesSet);
            const selectionEmpty = newSelectedMeasureCodesSet.size === 0;
            return selectionChanged && !selectionEmpty;
        })
    );

    newSelectedMeasureCodes$: Observable<string[]> = combineLatest(([this.availableMeasures$, this.newSelectedMeasureCodesSet$])).pipe(
        map(([availableMeasureCodes, checkedMeasureCodes]) => {
            const selectedMeasureCodes: string[] = [];
            for (const measure of availableMeasureCodes) {
                if (checkedMeasureCodes.has(measure.code)) {
                    selectedMeasureCodes.push(measure.code);
                }
            }
            return selectedMeasureCodes;
        })
    );

    @Output()
    saveSelectedMeasureCodes = new EventEmitter<string[]>();

    constructor() {
        combineLatest([this.availableMeasures$, this.currentSelectedMeasureCodesSet$]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            ([availableMeasures, currentSelectedMeasureCodesSet]) => {
                if (availableMeasures.length > 0 && currentSelectedMeasureCodesSet.size === 0) {
                    this.resetSelection(availableMeasures);
                }
            }
        );

        this.save$.pipe(
            withLatestFrom(this.newSelectedMeasureCodes$, this.isSaveable$),
            takeUntil(this.destroy$)
        ).subscribe(([_, newSelectedMeasureCodes, isSaveable]) => {
            if (isSaveable) {
                this.saveSelectedMeasureCodes.emit(newSelectedMeasureCodes);
            }
        });
    }

    private resetSelection(availableMeasures: ModelMeasure[]): void {
        if (!availableMeasures) {
            console.warn('No available measures to reset selection');
            return;
        }

        const newSelectedMeasureCodes = availableMeasures.map(measure => measure.code).slice(0, Math.min(this.initialMeasureSelectionCount, availableMeasures.length));
        this.saveSelectedMeasureCodes.emit(newSelectedMeasureCodes);
    }

    save() {
        this.save$.next();
    }

    reset(event) {
        event.stopPropagation();

        this.availableMeasures$.pipe(
            take(1)
        ).subscribe(availableMeasures => {
            this.resetSelection(availableMeasures);
            this.isOpen = true;
        });
    }

    toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    onOutsideClick() {
        this.isOpen = false;
    }

    toggleChecked(index: number) {
        const clickedMeasure = this.availableMeasures$.getValue()[index];
        const clickedMeasureCode = clickedMeasure.code;
        const checkedMeasureCodes = new Set(this.newSelectedMeasureCodesSet$.getValue());
        if (checkedMeasureCodes.has(clickedMeasureCode)) {
            checkedMeasureCodes.delete(clickedMeasureCode);
        } else {
            checkedMeasureCodes.add(clickedMeasureCode);
        }
        this.newSelectedMeasureCodesSet$.next(checkedMeasureCodes);
    }

    isEqualSets(a: Set<string>, b: Set<string>): boolean {
        return a.size === b.size && Array.from(a).every(value => b.has(value));
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type MeasureSelectorState = {
    code: string;
    name: string;
    checked: boolean;
};
