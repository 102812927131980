import { NgZone } from '@angular/core';
import { ChartData, DonutChartConfig } from '../services/chart.models';
import { MnbUnitInfo, MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { ChartRenderer } from './chart-renderer';

import * as _d3 from 'd3';
import { MnbChartTooltipData } from '@shared-lib/modules/charts/components/chart-tooltip-content.component';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';
import { PieArcDatum, Arc, DefaultArcObject } from 'd3';


export class DonutChartRenderer extends ChartRenderer {

    private paddingForLabels: number;
    private data: Array<PieArcDatum<ChartData>>;

    constructor(
        protected config: DonutChartConfig,
        zone: NgZone,
        unitPipe: MnbUnitPipe,
        emptyPipe: MnbEmptyValuePipe,
        unitService?: MnbUnitService
    ) {
        super(zone, unitPipe, emptyPipe, unitService);
        this.paddingForLabels = this.config.paddingForLabels;
        const pie = d3.pie<ChartData>().value((d: ChartData) => {
            return d.value;
        }).sort(null); // set sorting to null to avoid 'spacer' (isTransparent = true) at the end to be reordered (we ordered everything anyways)

        // taking the first data only
        this.data = pie(this.config.data[0].data);
    }

    private drawLabelsWithLines(radius: number, donutArc: Arc<any, DefaultArcObject>): void {
        const valuesTotal = d3.sum(this.data, function (d) {
            return d.value;
        });
        const labelArc = d3.arc().outerRadius(radius * 0.9).innerRadius(radius * 0.9);

        // draw Labels
        this.gElement.append('g')
            .attr('class', 'donut-labels')
            .selectAll('text')
            .data(this.data)
            .enter().append('text')
            .html((d: PieArcDatum<ChartData>) => {
                return '<tspan x=\'0\' style=\'font-weight: bold\'>' + Math.round(100 * d.data.value / valuesTotal) + '%' + '</tspan>' +
                    '<tspan x=\'0\' dy=\'1em\'>' + d.data.label + '</tspan>';
            })
            .attr('class', 'donut-label')
            .attr('transform', (d: PieArcDatum<ChartData>): string => {
                const pos = labelArc.centroid(<any>d);
                const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                pos[0] += midAngle < Math.PI ? 3 : -3;
                pos[1] -= 8 * Math.cos(midAngle);
                return 'translate(' + pos + ')';
            })
            .style('display', (d: PieArcDatum<ChartData>): string => this.calcLabelDisplayValue(d))
            .style('text-anchor', (d: PieArcDatum<ChartData>) => {
                const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                return (midAngle) < Math.PI ? 'start' : 'end';
            });

        // draw label Lines
        this.gElement.append('g')
            .attr('class', 'donut-label-lines')
            .selectAll('polyline')
            .data(this.data)
            .enter().append('polyline')
            .attr('points', (d: any): any => {
                return [[donutArc.centroid(d)], labelArc.centroid(d)];
            })
            .style('display', (d: PieArcDatum<ChartData>): string => this.calcLabelDisplayValue(d));
    }

    private calcLabelDisplayValue(d: PieArcDatum<ChartData>): string {
        if (d.data.isTransparent) {
            return 'none';
        }
        // only if more then 5 % of the pie
        return (d.endAngle - d.startAngle) < (Math.PI * 2 * 0.05) ? 'none' : 'inherit';
    }

    private drawDonut(donutArc: Arc<any, DefaultArcObject>, highlightArc: Arc<any, DefaultArcObject>, el, radius: number): void {

        // create donut
        this.gElement.append('g')
            .attr('class', 'donut')
            .selectAll('path')
            .data(this.data)
            .enter().append('path')
            .style('fill', (d: any) =>  d.data.isTransparent ? 'none' : d.data.color)
            .attr('class', (d: any) => {
                return 'donut-slice chart-color-' + (d.data.isTransparent ? 'transparent' : d.index);
            })
            .attr('d', <null>donutArc)
            .on('mouseenter', (d: PieArcDatum<ChartData>, i: number) => {

                if (this.config.data[0].data[i].isTransparent) {
                    // transparent # slice has no tooltip
                    return;
                }

                const rad = (d.startAngle + d.endAngle) / 2;

                const left = this.svgHTMLElement.getBoundingClientRect().width / 2 + (Math.sin(rad) * radius * 0.6);
                const top = this.svgHTMLElement.getBoundingClientRect().height / 2 - (Math.cos(rad) * radius * 0.6);

                this.gElement.selectAll('.highlight-slice')
                    .filter(`.chart-color-${i}`)
                    .style('visibility', 'visible');

                const scrollingTop = document.scrollingElement.contains(el) ? document.scrollingElement.scrollTop : 0;

                // calculate placement
                const relativeMousePosition = d3.event.pageX / window.innerWidth;
                if (relativeMousePosition < 0.25) {
                    this.tooltipContent.tooltipOwner.tooltipPlacement = 'right';
                } else if (relativeMousePosition > 0.75) {
                    this.tooltipContent.tooltipOwner.tooltipPlacement = 'left';
                } else if (rad > Math.PI) {
                    this.tooltipContent.tooltipOwner.tooltipPlacement = 'left';
                } else {
                    this.tooltipContent.tooltipOwner.tooltipPlacement = 'right';
                }

                this.tooltipContent.tooltipData = this.initTooltip(i);

                this.tooltipContent.tooltipOwner.showTooltipOnPosition(
                    this.svgHTMLElement.getBoundingClientRect().top + scrollingTop + top,
                    this.svgHTMLElement.getBoundingClientRect().left + left);

                this.tooltipContent.tooltipOwner.detectChanges();
            })
            .on('mouseleave', (d: PieArcDatum<ChartData>, i: number) => {
                this.gElement.selectAll('.highlight-slice').filter(`.chart-color-${i}`).style('visibility', 'hidden');
                this.tooltipContent.tooltipOwner.hideTooltip();
            });


        // create "highlight" donut which is an outer ring around the main donut
        this.gElement.append('g')
            .attr('class', 'donut')
            .selectAll('path')
            .data(this.data)
            .enter()
            .append('path')
            .style('fill', (d: any) => d.data.color)
            .attr('class', (d: any) => {
                return 'donut-slice highlight-slice chart-color-' + (d.data.isTransparent ? 'transparent' : d.index);
            })
            .style('visibility', 'hidden')
            .attr('d', <null>highlightArc);

    }

    public async render(el: HTMLElement | HTMLElement[]) {

        // change this later when mnb-chart passes in ele[0] instead of ele
        const temp: any = el;
        const ele: HTMLElement = temp.length ? temp[0] : temp;

        // el is basically a HTMLELement
        this.svgElement = d3.select(ele).append('svg')
            .attr('class', 'mnb-chart mnb-pie-chart' + (this.config.style.multiColor ? ' mnb-multi-color-chart' : ''))
            .attr('width', '100%')
            .attr('height', '100%');

        this.svgHTMLElement = this.svgElement.node();

        const dimensions: { height: number, width: number } = await super.getDimensions(ele);
        const width = dimensions.width - this.paddingForLabels;
        const height = dimensions.height;
        const radius = Math.min(width, height) / 2;
        const donutArc = d3.arc().outerRadius(radius * 0.9).innerRadius(radius * 0.4).cornerRadius(0);
        const highlightArc = d3.arc().outerRadius(radius).innerRadius(radius * 0.9).cornerRadius(0);

        this.gElement = this.svgElement
            .append('g')
            .attr('transform', 'translate(' + (width + this.paddingForLabels) / 2 + ',' + height / 2 + ')');

        // draw donut
        this.drawDonut(donutArc, highlightArc, ele, radius);
        // draw labels with lines
        if (!this.config.xAxisConfig.hide && !this.config.yAxisConfig.hide) {
            this.drawLabelsWithLines(radius, donutArc);
        }
    }



    private initTooltip(index: number) {

        const tooltipData = new MnbChartTooltipData();

        tooltipData.withShares = true;

        const dataArray = this.config.data[0];
        const d = dataArray.data[index];

        const unit = this.config.value.unit;
        let unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo> unit : new MnbUnitInfo(<string> unit);

        if (unitInfo.isOriginalCurrency && dataArray.containsCurrency) {
            unitInfo = new MnbUnitInfo(unitInfo.code, true, d.label);
        }

        const unitCode: string = unitInfo.code;

        tooltipData.values = {
            unitCode: unitCode,
            unit: unitInfo
        };

        tooltipData.headerData = {
            label: this.config.value.label,
            value: this.config.total,
            sum: dataArray.data.map(data => data.value).reduce((a, b) => a + b, 0)
        };

        tooltipData.data = {
            label: d.label,
            value: d.value
        };

        if (this.config.data.length > 1) {
            tooltipData.withComparison = true;
            tooltipData.headerData.comparisonSum = this.config.data[1].data.map(data => data.value).reduce((a, b) => a + b, 0);
            tooltipData.data.comparisonValue = this.config.data[1].data[index].value;
        }

        return tooltipData;
    }

}
